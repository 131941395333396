import type { RouteObject } from "react-router-dom";
import { paths } from "@configs/routes/paths";

export const publicRoutes: RouteObject = {
  path: paths.search,
  async lazy() {
    let { PublicLayout } = await import("@src/pages/public");
    return { Component: PublicLayout };
  },
  children: [
    {
      index: true,
      async lazy() {
        let { SearchAndRegister } = await import("@src/pages/public");
        return { Component: SearchAndRegister };
      },
    },
    {
      async lazy() {
        let { RegisterLayout } = await import("@src/pages/public");
        return { Component: RegisterLayout };
      },
      children: [
        {
          path: `${paths.search}/agent/:id`,
          async lazy() {
            let { AgentRegister } = await import("@src/pages/public");
            return { Component: AgentRegister };
          },
        },
        {
          path: `${paths.search}/association/:id`,
          async lazy() {
            let { AssociationRegister } = await import("@src/pages/public");
            return { Component: AssociationRegister };
          },
        },
      ],
    },
  ],
};

export const publicRoutesTemp: RouteObject = {
  path: paths.group,
  async lazy() {
    let { PublicLayout } = await import("@src/pages/public");
    return { Component: PublicLayout };
  },
  children: [
    {
      index: true,
      async lazy() {
        let { TempPageGroup } = await import("@src/pages/public");
        return { Component: TempPageGroup };
      },
    },
    {
      async lazy() {
        let { RegisterLayout } = await import("@src/pages/public");
        return { Component: RegisterLayout };
      },
      children: [
        {
          path: `${paths.group}/public/:groupData/:groupId`,
          async lazy() {
            let { TempPageGroup } = await import("@src/pages/public");
            return { Component: TempPageGroup };
          },
        },
      ],
    },
  ],
};

export const publicRoutesTempAssociation: RouteObject = {
  path: paths.associationV2,
  async lazy() {
    let { PublicLayout } = await import("@src/pages/public");
    return { Component: PublicLayout };
  },
  children: [
    {
      index: true,
      async lazy() {
        let { TempPageGroup } = await import("@src/pages/public");
        return { Component: TempPageGroup };
      },
    },
    {
      async lazy() {
        let { RegisterLayout } = await import("@src/pages/public");
        return { Component: RegisterLayout };
      },
      children: [
        {
          path: `${paths.associationV2}/:groupId`,
          async lazy() {
            let { TempPageGroup } = await import("@src/pages/public");
            return { Component: TempPageGroup };
          },
        },
      ],
    },
  ],
};

export const publicRoutesTempAssociationV2: RouteObject = {
  path: paths.dashboard,
  async lazy() {
    let { PublicLayout } = await import("@src/pages/public");
    return { Component: PublicLayout };
  },
  children: [
    {
      index: true,
      async lazy() {
        let { TempPageGroup } = await import("@src/pages/public");
        return { Component: TempPageGroup };
      },
    },
    {
      async lazy() {
        let { RegisterLayout } = await import("@src/pages/public");
        return { Component: RegisterLayout };
      },
      children: [
        {
          path: `${paths.dashboard}/group-list/public/:groupId/overview`,
          async lazy() {
            let { TempPageGroup } = await import("@src/pages/public");
            return { Component: TempPageGroup };
          },
        },
      ],
    },
  ],
};
