export const paths = {
  emailCheck: "/emailCheck",
  login: "/login",
  signup: "/signup",
  confirmCode: "/confirmCode",
  forgotPassword: "/forgotPassword",
  changePassword: "/changePassword",
  initialProfileSetup: "/initialProfileSetup",
  aboutUs: "/about-us",
  policy: "/policy",
  education: "/education",
  news: "/news",
  podcast: "/podcast",
  events: "/events",
  workWithUs: "/workWithUs",
  help: "/help",
  terms: "/terms",
  userDashboard: "/userDashboard",
  home: "/home",
  userAssociationList: "/userAssociationList",
  user: "/user",
  association: "/association",
  associationDashboard: "/associationDashboard",
  search: "/searchAndRegister",
  profileSetup: "/profileSetup",
  group: "/group",
  associationV2: "/association_l2",
  dashboard: "/dashboard",
} as const;

export type PathsType = typeof paths;
